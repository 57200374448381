
import { Component, Vue } from 'vue-property-decorator'
import { FormInfo } from '../../types/information'

@Component({
  name: 'InformationDetail'
})
export default class extends Vue {
  private detailInfo: FormInfo= {
    informationTile: '',
    informationContent: '',
    informationIntroduction: '',
    isShow: null,
    tagList: [],
    resource: null
  }

  created () {
    this.getDetail()
  }

  // 获取详情
  getDetail () {
    this.$axios.get(this.$apis.information.selectInformationDetail, {
      informationId: this.$route.params.id
    }).then(res => {
      this.detailInfo = res
    })
  }
}
